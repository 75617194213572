<template>
<div>


<br>
Auth: {{authenticated}}
<br>
User: {{user}}
<br>
Check: {{check}}
<br>
Token: {{token}}
<br>

      <div v-if="user">
      User A: {{user.is_activated}}
      User B: {{user.is_it_boarded}}
      </div>



</div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {

  data () {
        return {
        //authenticated: '',
       // user: '',
        // check: '',
        // token: ''
        
        }
  },
   methods: {

  },
  mounted () {

    },
    
  computed: mapGetters({
    user: 'auth/user',
    authenticated: 'auth/authenticated',
    check: 'auth/check',
    token: 'auth/token',
  }),
 
}

</script>
